<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <a-form-model-item label="批次名称(不能重复)" prop="name" >
        <a-input v-model="form.name" placeholder="请输入批次名称(不能重复)" />
      </a-form-model-item>
      <a-form-model-item label="用户id关联 {zb_user.id}" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id关联 {zb_user.id}" />
      </a-form-model-item>
      <a-form-model-item label="品牌id关联{zb_brand.id}" prop="brandId" >
        <a-input v-model="form.brandId" placeholder="请输入品牌id关联{zb_brand.id}" />
      </a-form-model-item>
      <a-form-model-item label="规格id关联{zb_goods_sku.id}" prop="skuId" >
        <a-input v-model="form.skuId" placeholder="请输入规格id关联{zb_goods_sku.id}" />
      </a-form-model-item>
      <a-form-model-item label="品牌产品id 关联{zb_brand_sku.id}多个以，拼接" prop="brandProduct" >
        <a-input v-model="form.brandProduct" placeholder="请输入品牌产品id 关联{zb_brand_sku.id}多个以，拼接" />
      </a-form-model-item>
      <a-form-model-item label="产品数量" prop="num" >
        <a-input v-model="form.num" placeholder="请输入产品数量" />
      </a-form-model-item>
      <a-form-model-item label="批次说明" prop="notes" >
        <a-input v-model="form.notes" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="弹出类型：0-写入" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="设备id" prop="deviceId" >
        <a-input v-model="form.deviceId" placeholder="请输入设备id" />
      </a-form-model-item>
      <a-form-model-item label="code" prop="code" >
        <a-input v-model="form.code" placeholder="请输入code" />
      </a-form-model-item>
      <a-form-model-item label="口味id多个以，拼接" prop="flavorsId" >
        <a-input v-model="form.flavorsId" placeholder="请输入口味id多个以，拼接" />
      </a-form-model-item>
      <a-form-model-item label="时间戳" prop="writingTime" >
        <a-date-picker style="width: 100%" v-model="form.writingTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRecord, addRecord, updateRecord } from '@/api/cigarette/record'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        name: null,

        userId: null,

        brandId: null,

        skuId: null,

        brandProduct: null,

        num: null,

        notes: null,

        type: null,

        deviceId: null,

        code: null,

        flavorsId: null,

        writingTime: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '批次名称(不能重复)不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        name: null,
        userId: null,
        brandId: null,
        skuId: null,
        brandProduct: null,
        num: null,
        notes: null,
        type: null,
        deviceId: null,
        code: null,
        flavorsId: null,
        writingTime: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRecord({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateRecord(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addRecord(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
